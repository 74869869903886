#footer {
  .root {
    position: relative;
    z-index: 10;
    text-align: center;
    padding-bottom: $spacing5
  }
  .logo {
    @include palette-text-secondary;
    @include breakpoints-down(md) {
      margin-top: $spacing5
    }
    img {
      filter: grayscale(1) contrast(0.5) brightness(1.5);
      width: 80px;
      margin-bottom: $spacing2
    }
    h4 {
      text-transform: uppercase
    }
  }
  .margin {
    margin: $spacing2
  }
  .socmed {
    margin: spacing(3, 0);
    a,btn-icon {
      margin: $spacing1;
      width: 36px;
      height: 36px;
    }
    i {
      font-size: 24px
    }
  }
  .contact {
    @include palette-text-secondary;
  }
  .divider {
    margin: 12px;
    border: none;
    background: none
  }
  .download {
    margin: spacing(2, 0)
  }
  .tw {
    color: #20B1D1
  }
  .fb {
    color: #193CCC
  }
  .id {
    color: #0050FF
  }
  .ig {
    color: #A418E6
  }
}