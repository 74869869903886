.parallax-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  @include left(0);
  top: 0;
  z-index: 0;
  &.dots-wrap {
    z-index: 4
  }
  @include breakpoints-down(md) {
    display: none
  }
  .inner-parallax {
    height: 800px;
    width: 100%;
    position: absolute;
    display: block;
    & > div {
      height: 800px;
      width: 100%;
      display: block;
    }
    &.large {
      top: 10px;
    }
    &.medium {
      top: -130px;;
    }
    .Masthead {
      overflow: visible;
    }
    .parallax-figure {
      > div {
        display: block;
        position: absolute;
        top: 200px;
        width: 100%;
        height: 700px;
        svg {
          position: absolute
        }
      }
    }
  }
  .parallax-vertical {
    width: 100%;
    height: 100%;
    position: absolute;
    @include breakpoints-up(lg) {
      transform: scale(0.5)
    }
    @include breakpoints-down(xs) {
      display: none
    }
  }
  .parallax-dot {
    top: -200px;
    width: 845px;
    height: 1099px;
    @include use-theme(opacity, 0.1, 0.5);
    @include left(200px)
  }
  .parallax-triangle {
    top: 300px;
    @include use-theme(outline, $light-text-hint, $dark-text-hint);
    opacity: 0.1;
    width: 902px;
    height: 1042px;
    @include use-theme(stroke, $light-text-hint, $dark-text-hint);
    fill: transparent;
    stroke-width: 50px;
    @include right(-240px)
  }
}
