#banner_nav {
  .root {
    display: block;
    @include breakpoints-down(md) {
      padding-top: $spacing7;
      > .container {
        padding: 0 !important
      }
    }
  }

  .cover {
    z-index: 0;
    position: absolute;
    clip: rect(auto,auto, auto, auto);
    top: 0;
    @include left(0);
    height: 100%;
    width: 1080px;
    overflow: hidden;
  }

  .figure {
    display: block;
    width: 100%;
    height: 100%;
    .img {
      background-size: cover;
      background-attachment: fixed;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .overlay {
    @include use-theme(background-image, linear-gradient(120deg, $palette-secondary-main, $palette-primary-main), linear-gradient(120deg, $palette-secondary-dark, $palette-primary-dark));
    opacity: 0.75;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .banner {
    position: relative;
    padding: spacing(10, 3, 0);
    color: $palette-common-white;
    height: 500px;
    @include breakpoints-down(sm) {
      height: 420px;
      margin: 0 auto;
    }
    @include breakpoints-down(xs) {
      height: 400px;
      padding: spacing(2, 3, 0);
      align-items: flex-start
    }
    @include breakpoints-up(sm) {
      padding: $spacing10;
      margin: 0 auto;
    }
  }

  .text {
    position: relative;
    max-width: 720px;
    @include breakpoints-down(sm) {
      text-align: center;
    }
    @include breakpoints-down(xs) {
      padding: $spacing5 0;
      top: $spacing1
    }
    h2, h4, h5 {
      position: relative;
      @include bg-color-fade($palette-primary-main, 0.5);
      backdrop-filter: saturate(180%) blur(10px);
      padding: spacing(1, 2);
      display: inline-block;
      span {
        z-index: 1;
        position: relative;
      }
    }
    h2 {
      color: $palette-secondary-light
    }
  }
  .socmed {
    position: relative;
    margin-bottom: $spacing3;
    @include bg-color-fade($palette-primary-main, 0.5);
    backdrop-filter: saturate(180%) blur(10px);
    padding: spacing(1, 2);
    display: inline-block;
    a {
      z-index: 1;
      margin: $spacing1;
      color: $palette-primary-dark;
      width: 36px;
      height: 36px;
      i {
        color: rgba(255, 255, 255, 0.75)
      }
    }
    i {
      font-size: 24px
    }
  }

  .setting-icon {
    position: absolute;
    top: $spacing10;
    @include right($spacing10);
    @include breakpoints-down(md) {
      .btn-icon {
        visibility: hidden;
      }
    }
    i.material-icons {
      color: $palette-common-white !important
    }
  }

  a.download {
    font-size: 14px;
    width: auto;
    padding: spacing(0, 2);
    margin: 0;
    margin-right: $spacing3;
    border-color: $palette-common-white;
    color: $palette-common-white;
    height: auto;
  }
}