#hero-banner {
  .video {
    position: relative;
    z-index: 10;
    overflow: hidden;
    height: 500px;
    width: 120%;
    background: $palette-common-black;
    &::v-deep iframe {
      width: 100%;
      margin-top: -60px;
      margin-left: -10%
    }
    @include breakpoints-up(md) {
      height: 650px
    }
  }
  .illustration {
    width: 100%;
    height: 500px
  }
  .particle-background {
    position: absolute;
    width: 100%;
    height: 500px;
    top: 0;
    left: 0;
  }
  .slideshow {
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
      width: 100%;
    }
  }
  .slide-item {
    height: 500px;
    width: 100%;
  }
  .img {
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
  }
}